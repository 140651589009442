@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
/* 32ffd6 */

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  --color-secondary: #32ffd6;
  --color-fake: #ff575c;
  --color-secondary-partner: #33F5FF;
  --color-secondary-partner-2: #33FF9C;
  --gradient-primary: linear-gradient(to right, var(--color-secondary), var(--color-secondary-partner));
  --secondary-gradient: linear-gradient(to bottom right, var(--color-secondary), var(--color-secondary-partner-2));
  --three-gradient: linear-gradient(to right, var(--color-secondary-partner), var(--color-secondary), var(--color-secondary-partner-2));
  --dark-bg: #0A0B12;
}

* {
  scroll-behavior: smooth;
}

.primary-gradient {
  background: var(--gradient-primary);
}

.text-card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.5); /* white with 50% opacity */
  border-radius: 15px; /* round the corners of the div */
  overflow: hidden; /* hide the overflow caused by the pseudo-element */
}

.text-card::before {
  content: "";
  position: absolute;
  top: -2px; 
  right: -2px;
  bottom: -2px; 
  left: -2px;
  background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet); /* example gradient */
  z-index: -1; /* position the gradient under the div content */
  border-radius: inherit; /* apply the same border-radius as the div */
}


.secondary-gradient {
  background: var(--secondary-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}


.remove-sec-gradient {
  background: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: none;
  background-clip: none;
  color: white;
}

.bg-green {
  background: var(--color-secondary-partner-2);
}

.primary-border {
  border: 1px solid var(--color-secondary);
}

.primary-border-2 {
  border: 2px solid #32ffd64e;
}

.glow-video {
  box-shadow: 0 0 10px 7px rgba(50, 255, 214, 0.2);  /* Adjust as needed */
}

.glow-fake {

}

.text-fake {
  color: var(--color-fake)
}

.three-gradient {
  background: var(--three-gradient)
}

.dark-bg {
  background: var(--dark-bg);
}

.font-righteous {
  font-family: 'Righteous'
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media screen and (max-width: 768px) { /* Assuming 768px is your mobile breakpoint */
  .iframe-container {
      padding-top: 60%; /* Adjust this value based on how tall you want the iframe to be on mobile */
  }
  .article {
    display: inline-block;
    margin-right: 12px; 
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .right-column {
      display: none;
  }
  .slider {
    width: 100%;
    overflow-x: auto; /* or use scroll */
    white-space: nowrap; /* To ensure inline elements don't break onto the next line */
  }
  
  .slider > div {
    display: inline-block;
    width: 100%; /* or set a fixed width, e.g., width: 250px; */
  }
}
@media screen and (min-width: 768px) {
  .only-mobile {
      display: none;
  }
}


.font-secondary {
  color: var(--color-secondary)
}

.font-fake {
  color: var(--color-fake)
}

.bg-secondary {
  background: var(--color-secondary)
}

.bg-fake {
  background: var(--color-fake)
}

.font-secondary-outline {
  color: var(--color-secondary);
}

.border-secondary {
  border-color: var(--color-secondary);
  border-width: 2px;
  border-style: dashed;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa 0%,
    #bef3f5 20%,
    #9dedf0 40%,
    #7de7eb 60%,
    #5ce1e6 80%,
    #33bbcf 100%
  );
  z-index: -1;
}

.overlayStyle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading_text {
  color: #ffffff;      
  margin-top: 30px;        
  font-size: 18px;
}

.containerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #696969e9 0%,
    #3c3c3c 50%,
    #11101d 100%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff96 -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-quote-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}
/* 
.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
} */

.blue__gradient {
  background: linear-gradient(90deg, #33F5FF 0%, #33F5FF 100%);
  filter: blur(400px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  z-index: -1;
}

.darkblue__gradient {
  background: linear-gradient(90deg, #336CFF 0%, #7299FF 100%);
  filter: blur(400px);
  -webkit-backface-visibility: hidden;
-moz-backface-visibility: hidden;
-webkit-transform: translate3d(0, 0, 0);
  z-index: -1;
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.black-opac-bg {
  background: #000000b0;
}

.privacy_box {
  border: 1px solid #444444
}

.file_border_radius {
  border-radius: 10px 10px 0px 0px;
}

.slider {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
}
.slider > div {
  display: inline-block;
}

.slider::-webkit-scrollbar {
  display: none;
}

.article {
  width: 100%;
  margin-right: 15px; 
}

.arrow-button {
  cursor: pointer;
}

/* This targets screens 640px and larger (e.g., tablets and desktops) */
@media (min-width: 640px) {
  .article {
    width: calc(50% - 6px); 
    margin-right: 12px; 
  }
}

* {
  box-sizing: border-box;
}




/* For prediction Circles */

.circle-red {
  border: 2px solid #FF3333;
  border-radius: 10px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF3333;
}
.circle-light-red {
  border: 2px solid #FFB3B3;
  border-radius: 10px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFB3B3
}
.circle-yellow {
  border: 2px solid #FFF44F;
  border-radius: 10px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF44F;
}
.circle-light-green {
  border: 2px solid #b3ffdac4;
  border-radius: 15px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b3ffdac4;
}
.circle-green {
  border: 2px solid #33FF9C;
  border-radius: 10px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #33FF9C;
}
.circle-transparent {
  border: 2px solid #33FF9C;
  border-radius: 10px;
  width: 275px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #33FF9C;
  opacity: 0;
}


.fade-in-section {
  opacity: 1;  /* This should start as 1 */
  transform: translateY(0);  /* No transformation to start */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out, visibility 0.6s ease-out;
}

.fade-in-section:not(.is-visible) {
  opacity: 0;
  transform: translateY(50px);
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.uploaded-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fit-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.uploaded-image-height-fill {
  height: 240px;
  width: auto; 
  border-radius: 5px;
}

.blur-background {
  backdrop-filter: blur(10px);
}

.blur-effect {
  filter: blur(10px);
}